import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import Breadcrumb from "../../../components/breadcrumb"
import Checkbox from "../../../components/checkbox"
import Constrain from "../../../components/constrain"
import Paragraph from "../../../components/paragraph"
import Count from "../../../components/count"
import Grid from "../../../components/grid"
import Heading from "../../../components/heading"
import HyperCardMini from "../../../components/hyper-card-mini"
import Image from "../../../components/image"
import Inline from "../../../components/inline"
import Layout from "../../../layouts/default"
import PageTitle from "../../../components/page-title"
import Seo from "../../../components/seo"
import Stack from "../../../components/stack"
import StepsBox from "../../../components/steps-box"
import Text from "../../../components/text"
import TextLink from "../../../components/textlink"
import Trivia from "../../../components/trivia"

const WorkshopKalenderPage = () => {
  const data = useStaticQuery(graphql`
    query WorkshopAprilPageQuery {
      materialien: file(
        relativePath: { eq: "workshops/kalender/april/materialien.jpg" }
      ) {
        ...largeImage
      }
      postkarte: file(relativePath: { eq: "postkarte-fruehling.pdf" }) {
        publicURL
      }
      gedicht: file(relativePath: { eq: "gedicht-fruehling.pdf" }) {
        publicURL
      }
      beispiel01: file(
        relativePath: { eq: "workshops/kalender/april/beispiel-1.jpg" }
      ) {
        ...largeImage
      }
      beispiel02: file(
        relativePath: { eq: "workshops/kalender/april/beispiel-2.jpg" }
      ) {
        ...largeImage
      }
      soundboard: file(relativePath: { eq: "startseite/soundboard.jpg" }) {
        ...largeImage
      }
      ogImage: file(relativePath: { eq: "og_images/kalender.jpg" }) {
        childImageSharp {
          gatsbyImageData(width: 1200, height: 600, layout: FIXED)
        }
      }
    }
  `)

  return (
    <Layout backdrop="workshop-kalender-april">
      <Seo
        title="April"
        description="Der Frühling ist da! Und mit ihm kommt ein weiteres Jahreszeitengedicht von Hölderlin. Den Frühling mochte er wohl besonders gern. In seiner Zeit im Tübinger Turm hat er ganze 9 Gedichte zum Frühling verfasst. Insgesamt kommt das Wort »Frühling« sogar 77 Mal in seinen Gedichten vor."
        image={data.ogImage.childImageSharp.gatsbyImageData}
      />
      <Stack>
        <Breadcrumb
          items={[
            {
              title: "Digital",
              link: "/digital",
            },
            {
              title: "Workshops",
              link: "/workshops",
            },
            {
              title: "Wörter und Bilder",
              link: "/workshops/kalender",
            },
            {
              title: "April",
              link: "/workshops/kalender/april",
            },
          ]}
        />
        <Stack space={[24, 24, 32]}>
          <Stack>
            <PageTitle>April</PageTitle>
            <Paragraph dropcap={true}>
              Der Frühling ist da! Und mit ihm kommt ein weiteres
              Jahreszeitengedicht von Hölderlin. Den Frühling mochte er wohl
              besonders gern. In seiner Zeit im Tübinger Turm hat er ganze{" "}
              <Count>9</Count>
              Gedichte zum Frühling verfasst. Insgesamt kommt das Wort
              »Frühling« sogar <Count>77</Count> Mal in seinen Gedichten vor.
              Wir zeigen dir, wie man aus Hölderlins Frühlingsgedicht ganz
              leicht ein eigenes Gedicht machen kann.
            </Paragraph>
          </Stack>

          <Constrain>
            <Stack>
              <Stack>
                <Heading as="h2" level={2}>
                  Du brauchst...
                </Heading>
                <Stack space={4}>
                  <Checkbox space={3} id="checklist-1">
                    <Text as="span" size={4}>
                      Einen schwarzen Filzstift oder Edding
                    </Text>
                  </Checkbox>
                  <Checkbox space={3} id="checklist-2">
                    <Text as="span" size={4}>
                      Die Postkarte mit Hölderlins Jahreszeitengedicht
                    </Text>
                  </Checkbox>
                  <Checkbox space={3} id="checklist-3">
                    <Text as="span" size={4}>
                      Eine Kopie des Gedichts
                    </Text>
                  </Checkbox>
                </Stack>
                <Stack space={3}>
                  <Text>
                    <Text sans={true} as="strong">
                      Du hast kein Kalender-Bastel-Set?
                    </Text>{" "}
                    Dann kannst du dir die Postkarte und das Gedicht auch selbst
                    ausdrucken. Dafür holst du dir am besten einen Erwachsenen
                    zur Hilfe.
                  </Text>
                  <Inline space={3}>
                    <TextLink href={data.postkarte.publicURL}>
                      Postkarte ausdrucken
                    </TextLink>
                    <TextLink href={data.gedicht.publicURL}>
                      Gedicht ausdrucken
                    </TextLink>
                  </Inline>
                </Stack>
                <Image
                  image={data.materialien.childImageSharp.gatsbyImageData}
                  alt="Auf einem Kalenderblatt mit dem Titel Januar ist eine gemalte Schneeflocke neben einer blauen Postkarte mit Hölderlins Gedicht der Winter aufgeklebt"
                />
              </Stack>
            </Stack>
          </Constrain>

          <Constrain>
            <Stack>
              <Heading as="h2" level={2}>
                Deine Aufgabe Schritt für Schritt:
              </Heading>
              <Stack space={6}>
                <StepsBox step={1}>
                  <Paragraph>
                    Lies dir Hölderlins Gedicht zunächst einmal gut durch.{" "}
                  </Paragraph>
                </StepsBox>
                <StepsBox step={2}>
                  <Paragraph>
                    Nun geht es daran, aus Hölderlins Gedicht ein neues Gedicht
                    zu machen. Hast du die Kopie des Gedichts vor dir liegen?
                    Dann schnapp dir einen schwarzen Filzstift oder Edding, lege
                    am besten noch eine Unterlage darunter, und streiche so
                    viele Wörter aus dem Gedicht heraus, dass aus den
                    übriggebliebenen Wörtern und Buchstaben ein neues Gedicht
                    entsteht.
                  </Paragraph>
                </StepsBox>
                <StepsBox step={3}>
                  <Paragraph>
                    Zuletzt klebst du dein Gedicht auf deine Kalenderseite.
                    Daneben oder darunter kannst du die Postkarte mit Hölderlins
                    Frühlingsgedicht aus unserem Bastel-Set aufkleben oder das
                    Gedicht noch einmal selbst in den Kalender hineinschreiben.
                  </Paragraph>
                </StepsBox>
              </Stack>
            </Stack>
          </Constrain>

          <Stack space={6}>
            <Heading as="h2" level={4} align="center">
              So kann deine Kalenderseite aussehen:
            </Heading>
            <Grid columns={[1, 2]} space={3}>
              <Image
                image={data.beispiel01.childImageSharp.gatsbyImageData}
                alt="April"
              />
              <Image
                image={data.beispiel02.childImageSharp.gatsbyImageData}
                alt="April"
              />
            </Grid>
          </Stack>

          <Constrain>
            <Stack>
              <Trivia>
                So ein Gedicht bezeichnet man im Englischen als »Blackout-Poem«.
                Das heißt übersetzt so viel wie »Ausschwärz-Gedicht«.
              </Trivia>
              <Paragraph>
                Wir freuen uns über ein Bild von deiner Kalenderseite! Lass dir
                von einem Erwachsenen helfen und schicke uns ein Foto an{" "}
                <TextLink href="mailto:hoelderlinturm@tuebingen.de">
                  hoelderlinturm@tuebingen.de
                </TextLink>
              </Paragraph>
              <HyperCardMini
                image={data.soundboard}
                description={`Mit unserer Jahreszeiten-Gedicht-Maschine kannst du dir aus Hölderlins Jahreszeitengedichten übrigens noch weitere ganz eigene Gedichte zusammenmixen. Schau vorbei und probiere es aus!`}
                to="/jahreszeiten-gedicht-maschine"
              />
            </Stack>
          </Constrain>
        </Stack>
      </Stack>
    </Layout>
  )
}

export default WorkshopKalenderPage
